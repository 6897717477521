* {
  margin: 0;
  padding: 0;
}
body {
  line-height: normal;
  overflow: hidden;
  font-size: 0.8rem;
}

.graphikblack {
  font-family: "GraphikBlack", "GraphikBlackIE", Arial, sans-serif;
}
.graphikregular {
  font-family: "GraphikRegular", "GraphikRegularIE", Arial, sans-serif;
}
.graphikbold {
  font-family: "GraphikBold", "GraphikBoldIE", Arial, sans-serif;
}
.graphiksemibold {
  font-family: "GraphikSemibold", "GraphikSemiboldIE", Arial, sans-serif;
}
.graphikmedium {
  font-family: "GraphikMedium", "GraphikMediumIE", Arial, sans-serif;
}
.graphiklight {
  font-family: "GraphikLight", "GraphikLightIE", Arial, sans-serif;
}

h6,
h5,
h4,
h3,
h2,
h1,
select {
  margin: 0;
  font-family: "GraphikSemibold", "GraphikSemiboldIE", Arial, sans-serif;
}
.h6,
.h5,
.h4,
.h3,
.h2,
.h1,
.small,
.p {
  margin: 0;
  font-family: "GraphikRegular", "GraphikRegularIE", Arial, sans-serif;
}
h5,
.h5 {
  font-size: 16px;
}
.f-14 {
  font-size: 0.8rem;
  font-family: "GraphikMedium", "GraphikMediumIE", Arial, sans-serif;
  margin-bottom: 0;
}
select,
label {
  font-size: 0.8rem;
  margin-bottom: 0;
}
p {
  margin-bottom: 0;
}

/* ========== Common CSS Satrt ========== */

.hmcc-container {
  width: 1200px;
  margin: auto;
}
nav {
  height: 53px;
}
#appTabs {
  height: 40px;
  position: relative;
  /* top: 7px; */
}
#appTabs .d-inline-block {
  vertical-align: text-top;
}

#b9AppTabs .d-inline-block {
  vertical-align: text-top;
}

#b9AppTabs {
  height: 43px;
  position: relative;
  top: 7px;
}
#b9AppTabs img {
  width: 27px;
  height: 27px;
}
.tab-pane .new-subTablist #newSubTablist #b9AppTabs {
  height: auto;
}
#b9AppTabs .newTab-text {
  font-size: 18px;
}

/* 
.plr80{
  padding-left:80px ;
  padding-right:80px ;
} */
.plr-3 {
  padding-left: 3px;
  padding-right: 3px;
}
.mr-50 {
  margin-top: 50px;
}
.main-section {
  min-height: 100vh;
}
.HM-Main-BG {
  min-height: 100vh;
}
.main-section2 {
  padding-bottom: 30px;
  min-height: 100vh;
}
.second-nav {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 999;
}
.world-map {
  height: calc(100vh - 53px);
  overflow: hidden;
}
.hmcc-mr-auto {
  margin: auto;
}
.hmcc-d-flex {
  display: flex;
  justify-content: space-between;
}
.hmcc-flex-child {
  margin: 0.25rem;
}
.hmcc-flex-child h6 {
  font-size: 0.75rem;
  margin: 0.5rem 0 0.3rem 0;
  font-family: "GraphikMedium", Arial, sans-serif;
}
.hmcc-flex-child h2 {
  margin: 0;
}
.info-text {
  font-size: 1rem !important;
  font-family: "GraphikMedium", Arial, sans-serif;
  margin-bottom: 0.4rem;
}
.inner-tab-buttons {
  position: relative;
  top: -20px;
}
.hmcc-drop-down {
  display: none;
}
.hmcc-button {
  padding: 10px 3px;
  flex: 1;
  border-radius: 30px;
  text-align: left;
  margin: 3px 5px;
  outline: none;
}
.hmcc-drop-down-list {
  margin: 0 80px;
}
.hmcc-button h6 {
  font-size: 14px;
}

button:focus {
  outline: none;
}
#tabs {
  margin-top: 20px;
}
.nav li {
  width: 24%;
}
.main-hmcc-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
.data-sections2 {
  margin: 0 auto;
  width: 48%;
  padding: 15px;
  border-radius: 15px;
}
.border-inner {
  border: 1px solid;
  border-radius: 15px;
  padding: 15px;
}
.inner-date {
  background: #01166c;
  padding: 8px 20px;
  border-radius: 15px;
  margin: 5px 0;
}
.data-box,
.data-box2 {
  border-radius: 15px;
  padding: 15px;
  width: 100%;
  margin-top: 10px;
}
.data-box {
  min-height: 222px;
}
.data-box2 {
  min-height: 98%;
}
.tab-button-m {
  padding: 3px;
  margin: 0 5px;
  border-radius: 7px;
}
.tab-button-m:hover {
  padding: 3px;
  border-radius: 7px;
}
.tab-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 7.6vh;
  padding: 10px 5px;
  border-radius: 7px;
  text-align: left;
}
.down-arrow {
  display: none;
}
.data-container {
  padding: 1rem 1rem;
  border-radius: 15px 15px 15px 0px;
  margin-top: 2vh;
  height: calc(100vh - 227px);
}
.bottom-tab {
  border-radius: 30px;
  border: none;
  width: 185px;
  margin-top: 10px;
  padding: 10px 10px 10px 10px;
}
.bottom-tab h6 {
  font-size: 13px;
}
.bottom-tab:hover {
  border-radius: 0 0 30px 30px;
  margin-top: 0;
  padding: 20px 10px 10px 10px;
}
.bottom-tab-active {
  border-radius: 0 0 30px 30px;
  margin-top: 0;
  padding: 20px 10px 10px 10px;
}
.info-icon {
  box-shadow: 0 2px 5px #484848;
  border-radius: 50%;
  margin-left: 5px;
}
.dot {
  height: 18px;
  width: 18px;
  border-radius: 9px;
  margin: 0 auto 5px auto;
}
.info-list-buttons {
  position: absolute;
  bottom: 125px;
  left: 20%;
}
.toggle-icon3 {
  height: 40px;
  border-radius: 100px;
}
.toggle-bottom {
  display: flex;
  justify-content: center;
}
/*changes*/
.switch2-B9 {
  background: linear-gradient(
    -180deg,
    rgb(181, 227, 121) 0%,
    rgb(126, 195, 68) 100%
  );
}
.switch3 {
  width: 52px;
  border-radius: 36px;
  z-index: 1;
  transition: 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-property: left, right;
  transition-delay: 0s, 0.05s;
}
.infomation-icon {
  padding: 5px 8px 1px;
  border-radius: 11px;
  margin-left: 5px;
  font-size: 15px;
}
.hmcc-button option {
  font-size: 16px;
  line-height: 50px;
}

/* ========== Common CSS End ========== */

.hmcc-nav-text {
  margin: 8px 0 0 0;
  opacity: 0.5;
  padding: 10px 0;
  overflow: auto;
  line-height: 1.5;
  font-family: "GraphikMedium", Arial, sans-serif;
  font-size: 28px;
  border-bottom: 8px transparent solid;
}
.hmcc-nav-text:hover {
  text-decoration: none;
  opacity: 1;
}
.hmcc-nav-text-active {
  opacity: 1;
}
.hmcc-nav-text img {
  margin: 0 10px 7px 0;
}
.hmcc-client-name-hldr .hmcc-nav-text {
  opacity: 1;
  overflow: hidden;
}

.hmcc-client-name-hldr .hmcc-nav-text span {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.hmcc-logo-text {
  opacity: 1;
  overflow: visible !important;
  width: 30px;
  margin-top: 3px;
}
.transparent-bg-top {
  background-size: 100% !important;
  padding: 0 0.936rem 0.688rem 0.936rem !important;
  height: 3.7rem;
  border-radius: 0 0 1rem 1rem !important;
  position: relative;
  max-width: 80% !important;
  overflow: hidden !important;
}
.MuiTabs-root {
  min-height: 0px !important;
}

.HMFilter-btn-holder {
  position: absolute;
  right: 3%;
  top: 60px;
  z-index: 8;
  padding: 0.7rem;
  border-radius: 0 0 1rem 1rem;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 5rem;
  cursor: pointer;
}
.HMFilter-btn-holder img,
.hmcc-dd-section .filter-title img {
  height: 2.1rem;
  margin: 0.2rem;
  position: relative;
  top: -5px;
  left: 5px;
}

.filter-vertical-align {
  top: 13vh !important;
}
.hmcc-dd-section .dropdown {
  width: 100%;
  max-width: 280px;
}
/*changes*/
.hmcc-dd-section .hmcc-drop-btn.map-filter {
  border-radius: 0.25rem;
}
.hmcc-dd-section .hmcc-drop-btn.map-filter {
  border-radius: 0.25rem;
  margin: 0.6rem 1.6rem;
}
.hmcc-dd-section .hmcc-new-drop-btn-row {
  justify-content: flex-end;
  margin-right: 1.5rem;
}
.hmcc-new-drop-btn-row .hmcc-new-drop-btn:first-child {
  padding: 0rem;
}
.hmcc-new-drop-btn .applyBtn {
  height: 2.25rem;
  border-radius: 1.25rem;
  border: none;
  line-height: 1.5625rem;
  font-size: 0.875rem;
  margin: 0.188rem 0.313rem;
  padding: 0rem;
  width: 6rem;
  text-align: center;
  cursor: pointer;
  padding: 0.15rem 1.375rem 0.75rem;
}
.hmcc-new-drop-btn .applyBtn:hover {
  border: none;
}
.hmcc-new-drop-btn .clearBtn {
  height: 2.25rem;
  border-radius: 1.25rem;
  background: transparent;
  line-height: 1.5625rem;
  font-size: 0.875rem;
  margin: 0.188rem 0.313rem;
  padding: 0rem;
  width: 6rem;
  text-align: center;
  cursor: pointer;
  padding: 0.15rem 1.375rem 0.75rem;
}
.filterCloseBtn {
  cursor: pointer;
}
.hmcc-dd-options {
  border-radius: 0.5rem;
  margin: 0 1.5rem;
  width: 83%;
  position: absolute;
  z-index: 1;
}

.hmcc-dd-options .hmcc-searchbox #myInput,
.hmcc-dd-options .hmcc-searchbox #myInput1,
.hmcc-dd-options .hmcc-searchbox #myInput2,
.hmcc-dd-options .hmcc-searchbox #myInput3,
.hmcc-dd-options .hmcc-searchbox #myInput4,
.HMCCtxtFld {
  margin: 0.25rem 0.5rem;
  border-radius: 0.5rem !important;
}
.hmcc-dd-options-footer {
  display: flex;
  height: 3rem;
  justify-content: flex-end;
  align-items: center;
}
.hmcc-drop-selectedItems {
  position: relative;
  border-radius: 0.90625rem;
  padding: 0 1.5rem 0 0.5rem;
  margin: 5px 10px 5px 0;
  font-family: "GraphikMedium", Arial, sans-serif;
  font-size: 10px;
}
.map-filter-appliedFilters {
  border-radius: 4px;
}
.hmcc-drop-selectedItems #deleteIcon {
  position: absolute;
  right: 4px;
  top: 4px;
}
.FilterType {
  color: rgb(255, 255, 255);
  font-family: "GraphikMedium", Arial, sans-serif;
  font-size: 0.8rem;
}
/*tejinder.a.singh filter css end*/

.hmcc-dd-options .dropdown-content label.active {
  color: #fff;
}
.hmcc-dd-section h6 {
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: -0.35px;
  line-height: 1.375rem;
  margin-bottom: 0;
  margin-left: 0;
}
.hmcc-dd-section .filter-dropbtn-parent {
  border-radius: 1rem 0rem 0rem 1rem;
  bottom: 0;
  top: 9vh !important;
  width: 100%;
  max-width: 286px;
  height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.hmcc-dd-section .filter-header {
  justify-content: space-between;
  margin: 0.8rem 1.5rem;
  align-items: center;
}
.hmcc-dd-section .filter-title {
  display: flex;
  align-items: center;
}
.hmcc-dd-section .remove-extraspace {
  height: 25vh;
}
.row-divider {
  margin: 0.75rem -1rem;
}
.hmcc-dd-section .mobile-view {
  height: 0rem;
}
.hmcc-dd-options .checkbox-dropdown-items,
.HMCCtxtFld {
  margin: 0.125rem;
  outline: none;
  padding: 0rem 0.5rem;
  font-size: 0.9rem;
}
.hmcc-dd-section .checkbox-dropdown-items:hover,
.HMCCtxtFld:hover {
  border-radius: 0px;
}
.HMCCtxtFld {
  border-radius: 1rem !important;
  padding: 0.2rem 1rem 0.2rem 2rem;
  width: 13.5rem;
  background-size: 16px;
}
.hmcc-dd-section .hmcc-dd-options .dropdown-content {
  overflow-x: hidden;
}

.hmcc-dd-options .dropdown-content {
  width: 14rem !important;
}

/*changes*/
/* .hmcc-dd-section .filter-dropbtn-parent .map-filter {
  
  min-width: 12rem !important;
  position: relative;
  left: 12px !important;
} */

.hmcc-nav .tab-height-responsive {
  font-size: 0.8rem !important;
  cursor: pointer;
}
.hmcc-text {
  margin: 8px 0 0 0;
  padding: 5px 0;
}
.box-margin {
  margin: 0 30px;
}
.transparent-bg-bottom {
  background-size: 100%;
  height: 80px;
  border-radius: 1rem 1rem 0 0;
  padding: 0.5rem 0 0.5rem 0;
  max-width: 100% !important;
  transform: scale(0.8);
  transform-origin: bottom center;
}
.center-bottons {
  padding: 5px 0 0 0;
}
.center-bottonsB9 {
  padding: 5px 0 0 45px;
}

/* oparations */
.hm-d-flex {
  display: flex;
}
.hm2-d-flex {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
/* toggle */

.b {
  display: block;
}
.toggle-icon {
  position: absolute;
  top: 66px;
  left: 130px;
  width: 99px;
  height: 40px;
  border-radius: 100px;
}
.toggle-icon2 {
  left: 195px;
}
.toggle {
  position: absolute;
  top: 35px;
  left: 30%;
  width: 100px;
  height: 30px;
  border-radius: 100px;
  margin: -20px -40px;
}
.toggle2 {
  left: 75%;
}
.toggletext {
  position: absolute;
  top: 0.875rem;
  font-family: "GraphikSemibold", Arial, sans-serif;
  font-size: 1.25rem;
  left: 9%;
}
.toggletext2 {
  left: 52%;
}
.check {
  position: absolute;
  display: block;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 6;
}
.check:checked ~ .switch {
  right: -6px;
  left: 46px;
  transition: 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-property: left, right;
  transition-delay: 0.05s, 0s;
}
.check ~ .switch div,
.check ~ .switch small {
  opacity: 0.3;
}
.check:checked ~ .switch div,
.check:checked ~ .switch small {
  opacity: 1;
}
.switch {
  position: absolute;
  left: -4px;
  top: -17px;
  bottom: -12px;
  right: 46px;
  border-radius: 40px;
  z-index: 1;
  transition: 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-property: left, right;
  transition-delay: 0s, 0.05s;
}

.couting-text {
  color: #fff;
  position: relative;
  top: -7px;
  font-family: "GraphikSemibold", "GraphikSemiboldIE", Arial, sans-serif;
  font-weight: 600;
}

.couting-textB9 {
  color: black;
  position: relative;
  top: -6px;
  font-family: "GraphikMedium", "GraphikMediumIE", Arial, sans-serif;
  font-weight: 600;
}
.track {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: 0.1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  border-radius: 15px;
}
.track p {
  text-align: left;
  font-size: 18px;
  padding: 10px 13px;
  font-family: "GraphikBold", Arial, sans-serif;
}

.hmcc-footer2 {
  display: none;
}
/* Dropdown start */
.hmcc-drop-btn {
  padding: 2px 15px;
  width: auto;
  border-radius: 30px;
  text-align: left;
  margin: 0px 5px 0.25rem;
  outline: none;
  line-height: 25px;
  font-size: 0.75rem;
  font-family: "GraphikMedium", Arial, sans-serif;
}

.hmcc-drop-btn img {
  position: absolute;
  right: 31px;
  /*jaya*/
  top: 22px;
}
.dropbtn-parent {
  display: flex;
  position: relative;
  top: -20px;
  justify-content: center;
}
#myInput {
  box-sizing: border-box;
  background-position: 14px 12px;
  background-repeat: no-repeat;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  left: 10px;
  min-width: 190px;
  max-height: 250px;
  overflow-y: scroll;
  overflow: auto;
  z-index: 1;
}

.dropdown-content label {
  padding: 6px 10px;
  line-height: 12px;
  text-decoration: none;
  display: block;
}
.hmcc-dd-options .dropdown-content label {
  margin-bottom: 0;
  font-weight: 500;
}

.checkbox-dropdown-items {
  display: flex;
  align-items: center;
  padding: 0 15px;
  border-radius: 15px;
  margin-bottom: 1px;
}
/* new code 27/5 */

.hmcc-dd-options .checkbox-dropdown-items {
  margin: 0.125rem;
}
.hmcc-dd-section .checkbox-dropdown-items:hover {
  border-radius: 0px;
}

.buttons {
  border: none;
  padding: 0;
  outline: none;
  text-align: left;
  width: 120px;
  background: transparent;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.toggle-positions {
  position: relative;
  margin: auto;
}

.show {
  display: block;
}
.mobile-filter,
.hmcc-drop-btn-close {
  display: none;
}
.tab-content {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 150px;
}
.inner-scroll {
  margin-top: 10px;
  height: calc(100% - 60px);
  overflow-y: auto;
}
.inner-wo-scroll {
  margin-top: 10px;
  height: calc(100% - 60px);
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  border-radius: 3px;
}
.data-container2 {
  height: calc(100vh - 100px);
  padding: 15px 20px;
  border-radius: 15px;
}
.inner-date2 {
  height: 100%;
}
/* 002  end */

/*@media (min-width: 1600px) {
.container {
    max-width: 1500px;
}
}



/* laptop view <=1199 */
@media only screen and (max-width: 1199px) {
  .hmcc-nav-text {
    font-size: 22px;
  }

  nav {
    height: 55px;
    padding-top: 10px;
  }
  .transparent-bg-top {
    border-radius: 0px 0px 1rem 1rem;
    position: relative;
  }
  .transparent-bg-bottom {
    border-radius: 1rem 1rem 0 0;
  }
  .toggletext {
    left: 9%;
  }
  .toggletext2 {
    left: 52%;
  }
  /* Title section */
  .hm-d-flex {
    text-align: center;
    display: block;
    margin: auto;
  }
  .tab-button {
    height: auto;
  }
  .hm-d-flex img {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .bottom-tab {
    width: 150px;
  }
  .bottom-tab h6 {
    font-size: 14px;
  }
  .hmcc-button {
    width: 200px;
  }
  .hmcc-drop-down-list {
    margin: 0 0;
  }
  .hmcc-drop-btn {
    padding: 8px 5px;
    width: 180px;
  }
  .hmcc-drop-btn img {
    right: 13px;
  }
  .dropdown-content label {
    font-size: 12px;
  }
  .box-margin {
    margin: 0 0;
  }
  #tabs {
    justify-content: space-between;
  }
  .nav li {
    width: 22%;
  }
  /* New code 27/5 */
  .buttons {
    width: 100px;
  }
}
/* laptop view <=991 */
@media only screen and (max-width: 991px) {
  .main-section {
    height: auto;
  }
  .hmcc-nav-text {
    font-size: 14px;
  }
  nav {
    height: 53px;
    padding-top: 10px;
  }
  .transparent-bg-top {
    background: none;
    height: auto;
  }

  .hmcc-flex-child {
    width: 50%;
    height: auto;
    margin: 0;
    padding: 8px;
  }
  .hmcc-text {
    padding: 0 0;
    font-size: 14px;
  }
  .inner-tab-buttons {
    position: relative;
    top: 5px;
    left: 0;
    right: 0;
  }
  .data-box2 {
    min-height: 200px;
  }
  /* Title section */
  .bottom-tab {
    width: 100px;
    padding: 10px 5px;
  }
  .bottom-tab h6 {
    font-size: 12px;
  }
  .hm-d-flex h6 {
    font-size: 12px;
  }
  /* Footer */
  .transparent-bg-bottom {
    background: none;
    height: 96px;
  }
  .hmcc-footer {
    display: none;
  }
  .hmcc-footer2 {
    display: block;
  }
  .box1,
  .box2 {
    width: 50%;
    padding: 15px 10px;
  }

  .box1 small,
  .box2 small {
    padding: 6px 10px 5px 10px;
    font-size: 14px;
    position: relative;
    top: -4px;
    border-radius: 15px;
  }
  .box1 img,
  .box2 img {
    position: relative;
    top: -4px;
    margin-right: 10px;
  }
  .box1 h4,
  .box2 h4 {
    color: #fff;
  }
  .info-list-buttons {
    position: static;
  }
  .dropbtn-parent {
    flex-wrap: wrap;
    top: 0;
    margin: auto;
  }
  .data-sections2 {
    margin: 10px 15px 0 15px;
    width: 100%;
  }
  .hmcc-header-left {
    max-width: 30%;
  }
}
@media only screen and (max-width: 1400px) {
  .toggletext {
    left: 7%;
  }
  .toggletext2 {
    left: 49%;
  }
}
/* laptop view <=768 */
@media only screen and (max-width: 768px) {
  .hmcc-drop-btn-close {
    padding: 8px 10px;
    width: 72px;
    border-radius: 30px;
    text-align: left;
    margin: 3px 5px;
    outline: none;
  }
  .dropbtn-parent {
    padding-top: 20px;
  }
  .dropdown {
    width: 100%;
  }
  .hmcc-drop-btn {
    width: 100%;
  }
  .view {
    display: block;
  }
  .hmcc-col-4 {
    flex: 1;
    padding-right: 0px;
    padding-left: 0px;
  }
  .hmcc-nav-text img {
    display: block;
    margin: auto;
  }
  .hmcc-nav-text {
    font-size: 10px;
  }
  nav {
    height: 76px;
    padding-top: 10px;
  }
  .hmcc-nav {
    margin-left: 0;
    margin-right: 0;
    align-items: flex-end;
  }
  .hmcc-d-flex {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  }
  /* Operations */
  .inner-date {
    width: 100%;
    background: none;
    border-radius: 0;
    padding: 10px 0;
  }
  /*Title section  */
  .m-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .bottom-tab {
    margin-top: 0;
    background: none;
    border-radius: 0;
    padding: 5px;
    width: auto;
  }
  .tab-button {
    padding: 10px 0;
  }
  #tabs {
    justify-content: space-between;
  }
  .nav li {
    width: 24%;
  }
  .tab-button-m {
    margin: 0;
  }
  .hm2-d-flex {
    display: block;
  }
  /* new code 27/5 */
  .hmcc-drop-btn {
    margin: 3px 0;
  }
  .worldMapSvg {
    width: 150%;
    left: 4%;
  }
}
/* Mobile width <= 576px */
@media only screen and (max-width: 576px) {
  .bottom-tab h6 {
    font-size: 12px;
  }
  .bottom-tab:hover {
    background: none;
    border-radius: 0;
    padding: 5px;
  }
  .bottom-tab-active {
    background: none;
    border-radius: 0;
    padding: 5px;
  }
  .hm-d-flex {
    display: flex;
  }
  .down-arrow {
    display: block;
    margin-right: 10px;
  }

  .data-container {
    border-radius: 0;
    padding: 10px 10px;
  }
  .hmcc-button {
    width: 100%;
  }
  .hmcc-drop-down-list {
    display: block;
    margin: 0 32px;
  }
  .main-section,
  .main-section2 {
    padding-bottom: 0;
  }
  #tabs {
    justify-content: space-between;
  }
  .nav li {
    width: 49%;
    margin-bottom: 5px;
  }
  .tab-button-m {
    margin: 0;
  }
}

/* Mobile width <= 450px */
@media only screen and (max-width: 450px) {
  #tabs {
    display: block;
  }
  .nav li {
    width: 100%;
    margin-bottom: 5px;
  }
  .inner-date {
    display: block;
    position: relative;
  }
  .inner-date img {
    position: absolute;
    right: 10px;
    top: 25%;
  }
  .inner-date h6,
  .inner-date p {
    width: 85%;
  }
}

/* Mobile width <= 320px */
@media only screen and (max-width: 320px) {
  .m-p0 {
    padding-left: 0;
    padding-right: 0;
  }
  .hmcc-text img {
    display: block;
    text-align: center;
    margin-left: auto !important;
    margin-right: auto;
  }
  /* Title sections */

  .plr-3 {
    padding-left: 0;
    padding-right: 0;
  }
  .tab-button-m-active {
    display: block;
  }
  .data-box {
    border-radius: 15px;
  }
}
/* Navbar */
#hiddeNav {
  transition: ease-in-out 500ms;
  overflow: hidden;
}
.hiddennav,
.hiddennav2 {
  padding: 0;
  height: 0;
  overflow: hidden;
  transition: ease-in-out 500ms;
}
.hidebutton {
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
  z-index: 9999;
  border: none;
  padding: 3px 10px;
  border-radius: 0px 0px 15px 15px;
}
.hidebutton div {
  background: transparent;
  border: none;
  margin: 0 5px;
}
.imgchange {
  transform: rotate(180deg);
}
.hmcc-w {
  width: 30%;
}
.pined-h {
  display: none;
}
.pined {
  display: block;
}
.pin {
  display: none;
}
.worldMapSvg {
  top: 1rem;
  transform-origin: top;
  width: 100%;
  position: relative;
  height: 417px;
  z-index: 0;
  float: left;
  left: 24%;
}
/* Testing screen CSS */
@media only screen and (max-width: 5000px) and (min-width: 600px) {
  .main-hmcc-footer-test {
    position: absolute;

    bottom: -31px;
    left: 0;
    right: 0;
  }
  .world-map-test {
    height: 1010px;
    overflow: hidden;
  }

  .main-section3 {
    min-width: 3600px !important;
  }
  .hmcc-4 {
    flex: 0 0 1200px;
    max-width: 1200px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .intops-tab-button .new-intops-button-tabs {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding-left: 0px !important;
  }
  .worldMapSvg {
    width: 150%;
    left: 4%;
    top: 1rem;
  }
}
@media only screen and (max-width: 576px) {
  .hm-d-flex img {
    margin-bottom: 0rem;
  }
  .tab-pane .new-subTablist .newTab-text {
    margin-right: 1rem;
    line-height: 1rem;
  }
  .row-divider {
    margin: 0.75rem -0.625rem;
  }
}
@media only screen and (max-width: 1199px) {
  .intops-tab-button .new-intops-button-tabs {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding-left: 0px !important;
  }
  .intops-tab-button .new-intops-button-tabs img {
    margin-right: 0rem !important;
  }
}

@media screen and (min-height: 768px) and (max-height: 1023px) {
  .hmcc-dd-section .filter-dropbtn-parent {
    top: 6.8vh !important;
  }
  .selectClientHolderDiv {
    top: 6.8vh !important;
  }
  .worldMapSvg {
    width: 114%;
    left: 14%;
  }
}
@media screen and (min-height: 1024px) and (max-height: 1366px) {
  .hmcc-dd-section .filter-dropbtn-parent {
    top: 1vh !important;
  }
  .selectClientHolderDiv {
    top: 6vh;
  }
}

@media screen and (min-height: 851px) and (max-height: 900px) {
  .subtab-height1 {
    height: calc(100vh - 85px);
  }
  .subtab-height {
    height: calc(100vh - 150px);
  }
}

@media screen and (min-height: 801px) and (max-height: 850px) {
  .subtab-height1 {
    height: calc(100vh - 90px);
  }
  .subtab-height {
    height: calc(100vh - 170px);
  }
}

@media screen and (min-height: 750px) and (max-height: 800px) {
  .subtab-height1 {
    height: calc(100vh - 125px);
  }
  .subtab-height {
    height: calc(100vh - 185px);
  }
}

@media screen and (min-height: 701px) and (max-height: 749px) {
  .subtab-height1 {
    height: calc(100vh - 140px);
  }
  .subtab-height {
    height: calc(100vh - 200px);
  }
}

@media screen and (min-height: 650px) and (max-height: 700px) {
  .subtab-height1 {
    height: calc(100vh - 160px);
  }
  .subtab-height {
    height: calc(100vh - 215px);
  }
}

@media screen and (min-height: 600px) and (max-height: 649px) {
  .subtab-height1 {
    height: calc(100vh - 180px);
  }
  .subtab-height {
    height: calc(100vh - 235px);
  }
}
@media screen and (min-height: 550px) and (max-height: 599px) {
  .subtab-height1 {
    height: calc(100vh - 180px);
  }
  .subtab-height {
    height: calc(100vh - 255px);
  }
}
@media screen and (min-height: 500px) and (max-height: 549px) {
  .subtab-height1 {
    height: calc(100vh - 200px);
  }
  .subtab-height {
    height: calc(100vh - 270px);
  }
}
@media only screen and (max-width: 767px) {
  .mobile-view {
    position: fixed;
    left: 0;
    top: 0;
    display: none;
    bottom: 0;
    width: 260px;
    z-index: 10;
  }
  .mobile-filter,
  .hmcc-drop-btn-close {
    display: block;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) and (max-height: 1024px) {
  .hmcc-dd-section .filter-dropbtn-parent {
    top: 1vh !important;
  }
}
#foundation .new-subTablist {
  margin-bottom: 0.5rem;
}
.reactsimplemaps {
  transform: scaleX(1.11);
}
.mapMarkers:hover {
  stroke-width: 1px;
  cursor: pointer;
}

/* STYLES FOR SVM WHEEL MULTI SELECT DROPDOWN */
.multi-select-dropdown-with-count.reportingYrDropdown-checkbox1,
.multi-select-dropdown-with-count.reportingYrDropdown-checkbox1:hover {
  border: none !important;
  background-color: transparent !important;
}
.multi-select-dropdown-with-count.reportingYrDropdown-checkbox1 #mt-count-tooltip div[class*='-container'] > div[class*='-control'] > div:first-child {
  height: auto !important;
  margin: 0 0 0 3px !important;
  flex-wrap: nowrap !important;
}
.multi-select-dropdown-with-count.reportingYrDropdown-checkbox1 div[class*='-indicatorContainer'] > svg {
  height: 18px !important;
  width: 36px !important;
  fill: #fff !important;
}
.multi-select-dropdown-with-count.reportingYrDropdown-checkbox1 div[class*='-indicatorContainer']:hover > svg {
  fill: rgb(161, 0, 255) !important;
}
.multi-select-dropdown-with-count.reportingYrDropdown-checkbox1 .dropdown-buttons {
  margin: 0 !important;
}
.multi-select-dropdown-with-count.reportingYrDropdown-checkbox1 .dropdown-buttons span{
  color: #fff !important;
}
.multi-select-dropdown-with-count.reportingYrDropdown-checkbox1 div[class*=menu] {
  background-color: rgba(2,23,78,255) !important;
  border: 1px solid #fff !important;
}
.multi-select-dropdown-with-count.reportingYrDropdown-checkbox1 .dropdown-cancel button{
  margin-left: 4px !important;
}
.multi-select-dropdown-with-count.reportingYrDropdown-checkbox1 > #mt-count-tooltip > div[class*='-container'] > div[class*='-control'] > div > div[class*='-multiValue'] {
  padding: 0 !important;
  background-color: transparent !important;
  border-color: #fff !important;
}
.multi-select-dropdown-with-count.reportingYrDropdown-checkbox1>#mt-count-tooltip div[class*=control], .multi-select-dropdown-with-count.reportingYrDropdown-checkbox1>#mt-count-tooltip div[class*=control]:hover {
  height: 40px !important;
  background: transparent !important;
  border: 1px solid #fff !important;
}
.multi-select-dropdown-with-count.reportingYrDropdown-checkbox1 > #mt-count-tooltip div[class*='-control'] > div:nth-child(2) {
  width: 38px !important;
}
.multi-select-dropdown-with-count.reportingYrDropdown-checkbox1 .cvm-checked .svg-container {
  height: 10px !important;
  width: 10px !important;
}
.multi-select-dropdown-with-count.reportingYrDropdown-checkbox1 > #mt-count-tooltip {
  position: relative !important;
}
.multi-select-dropdown-with-count.reportingYrDropdown-checkbox1 > #mt-count-tooltip #dropdown-popover {
    display: none !important;
    z-index: 10000;
    box-shadow: 0 2px 16px 0 rgba(0,0,0,.25);
    border-radius: 10px;
    width: 350px;
    font-family: Graphik-Medium-Web;
    font-size: 14px;
    background: #fff;
    position: absolute;
    bottom: 0;
}
.multi-select-dropdown-with-count.reportingYrDropdown-checkbox1 > #mt-count-tooltip:hover #dropdown-popover {
  display: block !important;
  top: 44px !important;
  max-width: 200px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  height: fit-content !important;
  background-color: #02174e !important;
} 
.multi-select-dropdown-with-count.reportingYrDropdown-checkbox1 > #mt-count-tooltip #dropdown-popover .pop-title {
  color: #fff;
  padding: 10px;
}
.multi-select-dropdown-with-count.reportingYrDropdown-checkbox1 > #mt-count-tooltip #dropdown-popover .pop-body {
  padding: 0 20px 15px;
  display: flex;
  flex-wrap: wrap;
}
.multi-select-dropdown-with-count.reportingYrDropdown-checkbox1 > #mt-count-tooltip #dropdown-popover .pop-body .dropdown-tooltip{
font-size: 10px!important;
font-weight: 100;
color: #fff;
border-radius: 16px;
border: 1px solid #e7eaee;
margin: 2px;
padding: 5px;
width: fit-content;
line-height: 1;
}
.multi-select-dropdown-with-count.reportingYrDropdown-checkbox1 div[class*=option] {
  color: #fff !important;
}
.multi-select-dropdown-with-count.reportingYrDropdown-checkbox1 div[class*=option]:hover {
  background-color: #172f70 !important;
}
.multi-select-dropdown-with-count.reportingYrDropdown-checkbox1 div[class*=multiValue]>div:first-child {
  color: #fff !important;
}

/* FedEx color changes */
.switch2-B9 {
  background: #0099cc !important;
}